import { motion } from "framer-motion";
import Link from "next/link";
type Props = {
    showMsg: number
    Styles: any
    t:any
}

export default function ThirdItem({ showMsg, Styles, t }: Props) {
    return (
        <motion.div className={Styles.openMenu__thirdItem} initial={{ height: 0 }} style={showMsg === 3 ? { padding: 6 } : { padding: 0 }} animate={showMsg === 3 ? { height: '50px' } : { height: 0 }} exit={{ height: 0 }} transition={{ type: 'spring', duration: 0.1 }} >
            <div style={showMsg !== 3 ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                <span className={Styles.itemTitle}>{t.Header.Blog.ComingSoon}</span>
                <p className={Styles.itemDescription}>{t.Header.Blog.CSDesc}</p>
            </div>
        </motion.div>
    )
};
