import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import Styles from './styles.module.scss'
import React from 'react'
type Props = {
    name: string
    setShowMsg: any
    msg: number
    showMsg: number
}

export default function HeaderItem({ name, setShowMsg, msg, showMsg }: Props) {
    
    return (
        <div style={{ display: 'flex', marginTop: 10 }}>
            <span className={Styles.title}>{name}</span>
            {showMsg === msg ?

                <button style={{ border: 0 }} onClick={(() => { setShowMsg(0) })}>
                    <MdKeyboardArrowDown style={{ width: 15, height: 15, scale: 1.4 }} className={Styles.a} />
                </button>
   
                :
                <button style={{ border: 0 }} onClick={(() => { setShowMsg(msg) })}>
                    <MdKeyboardArrowUp style={{ width: 15, height: 15, scale: 1.4 }} className={Styles.a}/>
                </button>
            }
    
        </div>
    )
};
