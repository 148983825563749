import { motion } from "framer-motion";
import Link from "next/link";
type Props = {
    showMsg: number
    Styles: any
    t:any
}

export default function FirstItem({ showMsg, Styles, t }: Props) {
    return (
        <motion.div className={Styles.openMenu__firstItem} initial={{ height: 0 }} animate={showMsg === 1 ? { height: '98px', padding: 6 } : { height: 0 }} exit={{ height: 0 }} transition={{ type: 'spring', duration: 0.4 }} >
            <div style={showMsg !== 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                <Link href={'/quem-somos#quem-somos'} style={{ textDecoration: 'none' }}>
                    <span className={Styles.itemTitle}>{t.Header.CoinLivre.AboutUs}</span>
                </Link>
                <p style={{ marginBottom: 8 }} className={Styles.itemDescription}>{t.Header.CoinLivre.AUDesc}</p>
                <Link href="https://www.youtube.com/@metacast9068" target='_blank' style={{ textDecoration: 'none' }}>
                    <span className={Styles.itemTitle}>{t.Header.CoinLivre.Metacast}</span>
                </Link>
                <p className={Styles.itemDescription}>{t.Header.CoinLivre.MDesc}</p>
            </div>
        </motion.div>
    )
};
