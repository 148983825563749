import { motion } from "framer-motion";
import Link from "next/link";
import BrasilIcon from '@/assets/img/emojiBrasil.svg'
import SpainIcon from '@/assets/img/SpainEmoji.svg'
import USAIcon from '@/assets/img/USAemoji.svg'
import Image from "next/image";
import React, { Dispatch, SetStateAction } from "react";
import { useRouter } from "next/router";
type Props = {
    showMsg: number
    Styles: any
    setShowMsg: Dispatch<SetStateAction<number>>
    t:any,
}
type LanguageProps = {
    name: string
    img: any
    language: string
}[]
export default function LanguageItem({ showMsg, Styles, setShowMsg, t }: Props) {
    const router = useRouter();
    const { locale } = useRouter();
    const [language, setLanguage] = React.useState(locale ? locale.toUpperCase() : 'PT')
    function handleChangeLanguage(language: string) {
        router.push(router.pathname, router.asPath, { locale: language.toLowerCase() });
        setShowMsg(0)
      }
    const languages: LanguageProps = [
        {
            name: t.Header.Language.pt,
            img: BrasilIcon,
            language: 'PT'
        },
        {
            name: t.Header.Language.en,
            img: USAIcon,
            language: 'EN'
        },
        {
            name: t.Header.Language.es,
            img: SpainIcon,
            language: 'ES'
        },
    ]
    return (
        <motion.div className={Styles.openMenu__languageMenu} initial={{ height: 0 }} style={showMsg === 5 ? { padding: 6 } : { padding: 0 }} animate={showMsg === 5 ? { height: '110px' } : { height: 0 }} exit={{ height: 0 }} transition={{ type: 'spring', duration: 0.1 }} >
            <div style={showMsg !== 5 ? { visibility: 'hidden' } : { visibility: 'visible', display: 'flex', flexDirection: 'column', gap: 8 }}>
              {languages.map((language) => (
                <div key={language.name} style={{ display: 'flex', alignItems: 'start', gap: 5, cursor: 'pointer' }} onClick={(() => { setLanguage(language.language); handleChangeLanguage(language.language) })}>
                    <Image width={25} height={25} src={language.img} alt={language.name} />
                    <span className={Styles.languageItem}>{language.name}</span>
                </div>
              ))}
            </div>
        </motion.div>
    )
};
