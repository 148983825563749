import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Styles from "./styles.module.scss";
import React from "react";
import Image from "next/image";
import Link from "next/link";
type Props = {
	name: string;
	setShowMsg: any;
	msg: number;
	showMsg: number;
};

export default function HeaderProfile({
	name,
	setShowMsg,
	msg,
	showMsg,
}: Props) {
	function containsNumbers(text: string) {
		for (let i = 0; i < text.length; i++) {
			if (!isNaN(parseInt(text[i]))) {
				return true;
			}
		}
		return false;
	}
	const upperCaseName = name?.toUpperCase();
	const containsNumbersText = containsNumbers(upperCaseName);
	let userName = "";
	if (containsNumbersText) {
		const sliptNumber = upperCaseName?.split(" ");
		userName = sliptNumber[2][0];
	} else {
		userName = upperCaseName?.split(' ')[1].slice(0,1);
	}

	return (
		<div style={{ display: "flex", alignItems: "flex-end" }}>
			<span className={Styles.title}>{name}</span>
			<Image
				alt="Profile Picture"
				src={`/images/alphabet/${userName}.svg`}
				width={40}
				height={40}
				className={Styles.userLogo}
			/>

			{showMsg === msg ? (
				<button
					style={{ border: 0 }}
					onClick={() => {
						setShowMsg(0);
					}}
				>
					<MdKeyboardArrowDown
						style={{ width: 15, height: 15, scale: 1.4 }}
						className={Styles.a}
					/>
				</button>
			) : (
				<button
					style={{ border: 0 }}
					onClick={() => {
						setShowMsg(msg);
					}}
				>
					<MdKeyboardArrowUp
						style={{ width: 15, height: 15, scale: 1.4 }}
						className={Styles.a}
					/>
				</button>
			)}
		</div>
	);
}
