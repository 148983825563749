import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { Dispatch, SetStateAction } from "react";
type Props = {
	showMsg: number;
	Styles: any;
	setShowMsg: Dispatch<SetStateAction<number>>;
	administrator: boolean | undefined;
	locale: string | undefined;
	t: any
};
export default function UserMenuItem({
	showMsg,
	Styles,
	setShowMsg,
	administrator,
	locale,
	t
}: Props) {
	const defaultStyle = {
		textDecoration: "none",
	}
	return (
		<AnimatePresence>
			<motion.div
				className={Styles.openMenu__userMenu}
				initial={{ height: 0 }}
				style={showMsg === 4 ? { padding: 0 } : { padding: 0 }}
				animate={showMsg === 4 ? { height: "auto" } : { height: 0 }}
				exit={{ height: 0 }}
				transition={{ type: "spring", duration: 0.4 }}
			>
				<div
					className={Styles.menuProfile}
					style={
						showMsg !== 4 ? { visibility: "hidden" } : { visibility: "visible" }
					}
				>
					<Link href={"/perfil"} style={defaultStyle}>
						<span className={Styles.itemTitle}>{t.user_dropdown.acessar_carteira}</span>
					</Link>

					<Link href={"/perfil/comprar"} style={defaultStyle}>
						<span
							onClick={() => {
								localStorage.setItem("buyCNLTSessionDesktop", "true");
								setShowMsg(0);
							}}
							className={Styles.itemTitle}
						>
							{t.user_dropdown.comprar_cnlt}
						</span>
					</Link>

					<Link href={"/perfil/negociar"} style={{...defaultStyle, display: "none"}}> {/* desabilitado enquanto "Negociar" n�o for liberado */}
						<span
							className={Styles.itemTitle}
							onClick={() => {
								localStorage.setItem("buyDesktop", "true");
								setShowMsg(0);
							}}
						>
							{t.user_dropdown.investir_projetos}
						</span>
					</Link>
					<Link href={"/calculadora"} style={defaultStyle}>
							<span className={Styles.itemTitle}>{t.user_dropdown.calcular_ativos}</span>
					</Link>
					<Link href={"/perfil/historico"} style={defaultStyle}>
						<span className={Styles.itemTitle}>{t.user_dropdown.historico_transacoes}</span>
					</Link>

					<Link href={"/perfil/saque"} style={defaultStyle}>
						<span
							className={Styles.itemTitle}
							onClick={() => {
								localStorage.setItem("saque", "true");
								setShowMsg(0);
							}}
						>
							{t.user_dropdown.realizar_saque}
						</span>
					</Link>

					<Link
						style={defaultStyle}
						href="mailto:contato@blocklize.tech"
						locale={locale}
					>
						<span className={Styles.itemTitle}>{t.user_dropdown.ajuda}</span>
					</Link>

					<Link href={"/logout"} style={defaultStyle}>
						<span className={Styles.itemTitle}>{t.user_dropdown.desconectar}</span>
					</Link>
					{administrator && (
						<div>
							<div className={Styles.alternative2} />
							<Link
								href={"/controle"}
								style={defaultStyle}
							>
								<span
									className={Styles.itemTitle}
									onClick={() => {
										setShowMsg(0);
									}}
								>
									{t.user_dropdown.controle_administrador}
								</span>
							</Link>
						</div>
					)}
				</div>
			</motion.div>
		</AnimatePresence>
	);
}
