import { User } from "@/types/User";
import { motion } from "framer-motion";
import Link from "next/link";
import { Dispatch, SetStateAction } from "react";
type Props = {
    showMsg: number
    Styles: any
    setShowMsg: Dispatch<SetStateAction<number>>
    dataUser: User | undefined
    t:any
}

export default function SecondItem({ showMsg, Styles, setShowMsg, dataUser, t }: Props) {
    return (
        <motion.div className={Styles.openMenu__secondItem} initial={{ height: 0 }} animate={showMsg === 2 ?
            { height: '96px', padding: 6 }
            :
            { height: 0 }}
            exit={{ height: 0 }}
            transition={{ type: 'spring', duration: 0.4 }}
        >
            <div style={showMsg !== 2 ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                <Link href={dataUser?.name ? '/perfil' : '/login'} style={{ textDecoration: 'none' }}>
                    <span onClick={() => { !dataUser?.name ? setShowMsg(0) : localStorage.setItem('buyCNLTSessionDesktop', 'true'); setShowMsg(0) }} className={Styles.itemTitle}>{t.Header.MeetProjects.BuyCNLT}</span>
                </Link>
                <p className={Styles.itemDescription} style={{ marginBottom: 5 }}>{t.Header.MeetProjects.BuyCNLTDesc}</p>
                <Link href={'/quem-somos#projetos'} style={{ textDecoration: 'none' }}>

                    <span onClick={(() => { setShowMsg(0) })} className={Styles.itemTitle}>{t.Header.MeetProjects.OurProjects}</span>
                </Link>
                <p className={Styles.itemDescription}>{t.Header.MeetProjects.OPDesc}</p>
            </div>
        </motion.div>
    )
};
